import React, { useEffect, useState } from "react";
import { loadingBegin, loadingEnd } from "../actions/map";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import "../assets/scss/dashboard.scss";
import { getStatistics } from "../actions/profile";
import { getHighResOrdersForUser } from "../actions/task";

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";
import earthImg from "../assets/images/earth.png";
import timeImg from "../assets/images/time.png";
import Avatar from "react-avatar";
import { getRandomColor } from "../utils/backgroundColors";
import { getPieRandomColor } from "../utils/backgroundColors";
import dashBackground from "../assets/images/dash-background.png";
import loading from '../assets/images/loading_small.svg'
import { BsFillCloudArrowDownFill } from "react-icons/bs";

import { useSelector } from 'react-redux';
import cookies from "js-cookie";
import { Box, Grid } from "@mui/material";
import pdfIcon from "../assets/images/icons/pdf.svg";

import { addSubscription, downloadOrderReport } from '../actions/task';


ChartJS.register(ArcElement, Tooltip, Legend);

const pieOptions = {
    plugins: {
        tooltip: {
            callbacks: {
                label: (context) => `${context.label || ""}: ${context.formattedValue || ""} Ha`,
            },
        },
    },
};
const Home = ({ }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const taskId = useSelector(state => state.mapReducer.taskIdDetails)
    const user = useSelector((state) => state.auth.user);

    const [statistics, setStatistics] = useState({});
    const [highResOrders, setHighResOrders] = useState([]);
    const [data, setData] = useState({});
    const [gradientColor, setgradientColor] = useState(null);
    const currentLanguageCode = cookies.get("i18next") || "en";
    const [cultureData, setCultureData] = useState({});

    useEffect(() => {
        const fetchData = async () => {
            dispatch(loadingBegin());


            try {
                const res = await dispatch(getStatistics());
                if (res.status === 200) {
                    setStatistics(res.data.data);
                    setData(formatData(res.data.data.varieties, "variety_name_"));
                    setCultureData(formatData(res.data.data.cultures, "culture_name_"));
                }
            } catch (error) {
                console.error(error);
            } finally {
                dispatch(loadingEnd());
            }
        };


        fetchData();
    }, [dispatch, currentLanguageCode]);

    const formatData = (items, keyPrefix) => {
        const labels = [];
        const dataset = [];
        const colors = [];
        const usedColors = [];
        const key = keyPrefix + currentLanguageCode;
        items.forEach((item) => {
            labels.push(item[key]);
            dataset.push(item.surface || item.cultureSurface);
            colors.push(getPieRandomColor(usedColors));
        });
        return {
            labels,
            datasets: [
                {
                    data: dataset,
                    backgroundColor: colors,
                },
            ],
        };
    };
    useEffect(() => {
        if(!user) return;
        dispatch(getHighResOrdersForUser(user.id))
            .then((res) => {
                if (res.status === 200) {
                    setHighResOrders(res.data);
                } else {
                }
            })
            .catch((error) => { });

        dispatch(loadingEnd());
    }, [user]);

    return (
        <Grid container sx={{ flex: 1 }}>
            <Grid container spacing={2} sx={{ p: 2 }}>
                <Grid item xs={12} sm={6}>
                    <Grid container spacing={2} className="mb-3">
                        <Grid item xs={12} className="m-profile">
                            <div className="m-header">
                                <div className="m-text">
                                    <h5 className="m-headline">{t("Welcome Back !")}</h5>
                                    <p className="m-headline">{statistics.full_name}</p>
                                </div>
                                <div className="m-image">
                                    <img src={dashBackground} alt="Dashboard background" />
                                </div>
                            </div>
                            <div className="m-content d-none d-md-block">
                                <div className="m-profile-image">
                                    <Avatar color={getRandomColor()} name={statistics.full_name} round />
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} className="mb-2">
                        <Grid item xs={12} sm={6}>
                            <div className="m-card">
                                <div className="m-text">
                                    <p className="m-title">{t("Surface")}</p>
                                    <p className="m-value">{statistics.surface} Ha</p>
                                </div>
                                <div className="m-icon">
                                    <img className="m-image" src={earthImg} alt="Earth icon" />
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <div className="m-card">
                                <div className="m-text">
                                    <p className="m-title">{t("Last login")}</p>
                                    <p className="m-value">{statistics.last_login}</p>
                                </div>
                                <div className="m-icon">
                                    <img className="m-image" src={timeImg} alt="Time icon" />
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={6}>
                </Grid>

                <Grid item xs={12} sm={6}>
                    <Grid container spacing={2} className="mb-2">
                        <Grid item xs={12} sx={{ justifyContent: "center" }}>
                            <h3 className="m-title text-center">
                                {t("Agricultural distribution in hectares")}
                            </h3>
                        </Grid>
                        <Grid item xs={12} sx={{ justifyContent: "center" }}>
                            {data.labels && data.labels.length > 0 && (
                                <Pie data={data} options={pieOptions} style={{ maxHeight: "300px" }} />
                            )}
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Grid container spacing={2} className="mb-2">
                        <Grid item xs={12} sx={{ justifyContent: "center" }}>
                            <h3 className="m-title text-center">{t("Distribution of plantations in hectares")}</h3>
                        </Grid>
                        <Grid item xs={12} sx={{ justifyContent: "center" }}>
                            {cultureData.labels && cultureData.labels.length > 0 && (
                                <Pie data={cultureData} options={pieOptions} style={{ maxHeight: "300px" }} />
                            )}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            {highResOrders && highResOrders.length > 0 && <Grid container spacing={2} sx={{ p: 2 }}>
                <Grid item xs={12}>
                    <h4 className="header-title m-b-30">{t("High resolution imagery reports")}</h4>
                </Grid>
                <Grid item xs={12}>
                    <Grid spacing={2}>
                        <Box
                            sx={{ width: "100%", borderRadius: 1, bgcolor: "#fff", p: 2 }}
                            className="m-files-list"
                        >
                            {highResOrders.map((item) => {
                                if (item.orders.length != 0) {
                                    return (

                                        <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                                <h6 style={{ background: "#909090", borderRadius: "2px", width: "fit-Content", color: "white", padding: "2px" }}>{item.task_name}</h6>
                                            </Grid>
                                            {item.orders.map((order) => (
                                                <Grid item xs={12} sm={2}
                                                    onClick={item.order_status != "running" ? () => dispatch(downloadOrderReport(order.order_id, taskId, `${order.task_name}_${order.acquisition_date}`)) : null}>
                                                    <div className="file-man-box">
                                                        {item.order_status === "running" ? <img src={loading} width={"30px"} /> : <BsFillCloudArrowDownFill color='#6f9d2f' />}
                                                        <div className="file-img-box" style={{ height: "inherit" }}>
                                                            <img src={pdfIcon} alt="icon" height={50} />
                                                        </div>
                                                        <div className="file-man-title text-center">
                                                            <span
                                                                className="mb-0 text-overflow"
                                                                target="_blank"
                                                            >
                                                                <h6 className="mb-0 text-overflow">{order.acquisition_date}</h6>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    {/* </a> */}
                                                </Grid>
                                            )
                                            )
                                            }
                                        </Grid>
                                    )
                                }
                            }
                            )
                            }
                        </Box>
                    </Grid>
                </Grid>
            </Grid>}


            <Grid container spacing={2} sx={{ p: 2 }}>
                <Grid item xs={12}>
                    <h4 className="header-title m-b-30">{t("Monitoring reports")}</h4>
                </Grid>
                <Grid item xs={12}>
                    <Box sx={{ width: "100%", borderRadius: 1, bgcolor: "#fff", p: 2 }} className="m-files-list">
                        <Grid container spacing={2}>
                            {statistics.reports && statistics.reports.length > 0 ? (
                                statistics.reports.map((item, index) => (
                                    <Grid item xs={12} sm={2} key={index}>
                                        <a
                                            href={`${process.env.REACT_APP_API_URL}${item.file}`}
                                            className="mb-0 text-overflow"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            <div className="file-man-box">
                                                <div className="file-img-box">
                                                    <img src={pdfIcon} alt="icon" />
                                                </div>
                                                <div className="file-man-title text-center">
                                                    <h5 className="mb-0 text-overflow">{item.name}</h5>
                                                </div>
                                            </div>
                                        </a>
                                    </Grid>
                                ))
                            ) : (
                                <Grid item xs={12}>
                                    <span>{t("No available reports for the moment !")}</span>
                                </Grid>
                            )}
                        </Grid>
                    </Box>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default Home;
