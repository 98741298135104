import React, { useState, useEffect, useRef, useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getIndexArrayComparison, getGraphDataSelfTask } from "../../actions/graph";
import * as d3 from 'd3';
import { useTranslation } from "react-i18next";
import {getHistogram} from "../../actions/histogram";

const TaskReferenceComparisonGraph = ({ selectedIndex }) => {
    const svgRef = useRef(null);
    const taskId = useSelector((state) => state.mapReducer.taskIdDetails);
    const taskName = useSelector((state) => state.mapReducer.taskNameDetails);
    const date = useSelector((state) => state.mapReducer.selected_date);
    const checkboxLayers = useSelector((state) => state.mapReducer.checkboxLayers);
    const provider = useSelector((state) => state.mapReducer.SelectedDate.provider);

    const { t } = useTranslation();


    const [graphData, setGraphData] = useState({
        reference: [],
        currentYear: [],
        filteredReference: [],
        filteredCurrentYear: []
    });
    const dispatch = useDispatch();
    const defaultIndex = selectedIndex || "NDVI";

    const filterData = useCallback((data, index) => {
        if (!data || !Array.isArray(data)) return [];
        const filteredItem = data.find(item =>
            item.index.toLowerCase() === index.toLowerCase()
        );
        return filteredItem?.data_smoothed || [];
    }, []);

    const adjustReferenceToCurrentYear = (referenceData, currentYearData) => {
        if (!referenceData?.length || !currentYearData?.length) return [];

        const targetYear = new Date(currentYearData[0].date).getFullYear();

        const firstRefDate = new Date(referenceData[0].date);
        const firstCurrentDate = new Date(currentYearData[0].date);
        const monthDiff = firstCurrentDate.getMonth() - firstRefDate.getMonth();

        return referenceData.map(item => {
            const originalDate = new Date(item.date);
            let newMonth = originalDate.getMonth() + monthDiff;

            let newYear = targetYear;

            if (newMonth > 11) {
                newMonth -= 12;
            } else if (newMonth < 0) {
                newMonth += 12;
            }

            const newDate = new Date(
                targetYear,
                newMonth,
                originalDate.getDate()
            ).toISOString().split('T')[0];

            return {
                ...item,
                date: newDate
            };
        });
    };

    const dimensions = useMemo(() => {
        const margin = { top: 0, right: 0, bottom: 60, left: 40 };
        return {
            margin,
            width: svgRef.current?.parentElement?.clientWidth - margin.left - margin.right || 0,
            height: (250) - margin.top - margin.bottom
        };
    }, [svgRef.current]);

    useEffect(() => {
        if (!taskId) return;

        const fetchData = async () => {
            try {
                let currentYearData = [];
                try {
                    const currentYearResponse = await dispatch(getGraphDataSelfTask(taskId));
                    currentYearData = currentYearResponse.data.data || [];
                } catch (error) {
                    console.error('Error fetching current year data:', error);
                }

                let referenceData = [];
                try {
                    const referenceResponse = await dispatch(getIndexArrayComparison(taskId,date));
                    if(referenceResponse.response.status !== 200){
                        dispatch(getHistogram(taskId,date,provider,checkboxLayers))
                    }

                } catch (error) {
                    console.log('Reference data not available:', error);
                }

                const filteredCurrentYear = filterData(currentYearData, defaultIndex);
                const filteredReference = filterData(referenceData, defaultIndex);

                // Adjust reference dates to match current year
                const adjustedReference = adjustReferenceToCurrentYear(
                    filteredReference,
                    filteredCurrentYear
                );

                setGraphData({
                    reference: referenceData,
                    currentYear: currentYearData,
                    filteredReference: adjustedReference,
                    filteredCurrentYear: filteredCurrentYear
                });
            } catch (error) {
                console.error('Error in fetchData:', error);
            }
        };

        fetchData();
    }, [taskId, defaultIndex, filterData]);

    useEffect(() => {
        if (!graphData.currentYear && !graphData.reference) return;

        const filteredCurrentYear = filterData(graphData.currentYear, defaultIndex);
        const filteredReference = filterData(graphData.reference, defaultIndex);

        // Adjust reference dates to match current year
        const adjustedReference = adjustReferenceToCurrentYear(
            filteredReference,
            filteredCurrentYear
        );

        setGraphData(prev => ({
            ...prev,
            filteredReference: adjustedReference,
            filteredCurrentYear: filteredCurrentYear
        }));
    }, [defaultIndex, filterData]);

    // Rest of the component remains the same...

    useEffect(() => {
        const hasCurrentYearData = graphData.filteredCurrentYear?.length > 0;
        const hasReferenceData = graphData.filteredReference?.length > 0;

        if (!hasCurrentYearData && !hasReferenceData) {
            console.log('No data available to display');
            return;
        }
        if (!svgRef.current) return;

        const { width, height, margin } = dimensions;

        d3.select(svgRef.current).selectAll("*").remove();

        const svg = d3.select(svgRef.current)
            .attr('width', width + margin.left + margin.right)
            .attr('height', height + margin.top + margin.bottom)
            .style('background-color', 'white')
            .append('g')
            .attr('transform', `translate(${margin.left},${margin.top})`);

        svg.append("clipPath")
            .attr("id", "clip")
            .append("rect")
            .attr("width", width)
            .attr("height", height);

        const allData = [
            ...(hasCurrentYearData ? graphData.filteredCurrentYear : []),
            ...(hasReferenceData ? graphData.filteredReference : [])
        ];

        const xScale = d3.scaleTime()
            .domain(d3.extent(allData, d => new Date(d.date)))
            .range([0, width]);

        const yScale = d3.scaleLinear()
            .domain([0, d3.max(allData, d => d.value) * 1.1])
            .range([height, 0]);

        const line = d3.line()
            .x(d => xScale(new Date(d.date)))
            .y(d => yScale(d.value))
            .curve(d3.curveMonotoneX);

        const chartGroup = svg.append("g")
            .attr("clip-path", "url(#clip)");

        const drawLine = (data, color) => {
            if (!data?.length) return null;
            return chartGroup.append('path')
                .datum(data)
                .attr('fill', 'none')
                .attr('stroke', color)
                .attr('stroke-width', 1.5)
                .attr('d', line);
        };

        const currentYearPath = hasCurrentYearData ?
            drawLine(graphData.filteredCurrentYear, '#ff4081') : null;
        const referencePath = hasReferenceData ?
            drawLine(graphData.filteredReference, '#2196f3') : null;
        const xAxis = svg.append('g')
            .attr('class', 'x-axis')
            .attr('transform', `translate(0,${height})`);

        const yAxis = svg.append('g')
            .attr('class', 'y-axis');

        const updateAxes = (newXScale, newYScale) => {
            xAxis.call(d3.axisBottom(newXScale)
                .ticks(5)
                .tickFormat(d3.timeFormat('%Y-%m-%d')))
                .selectAll('text')
                .style('text-anchor', 'end')
                .attr('dx', '-.8em')
                .attr('dy', '.15em')
                .attr('transform', 'rotate(-45)')
                .style('font-size', '10px');

            yAxis.call(d3.axisLeft(newYScale)
                .ticks(5)
                .tickFormat(d => d.toString()))
                .selectAll('text')
                .style('font-size', '10px');
        };

        updateAxes(xScale, yScale);

        const zoom = d3.zoom()
            .scaleExtent([0.5, 5])
            .translateExtent([[0, 0], [width, height]])
            .extent([[0, 0], [width, height]])
            .on('zoom', (event) => {
                const { transform } = event;
                const newXScale = transform.rescaleX(xScale);
                const newYScale = transform.rescaleY(yScale);

                updateAxes(newXScale, newYScale);

                const updateLine = (path) => {
                    if (path) {
                        path.attr('d', line
                            .x(d => newXScale(new Date(d.date)))
                            .y(d => newYScale(d.value)));
                    }
                };

                if (currentYearPath) updateLine(currentYearPath);
                if (referencePath) updateLine(referencePath);
            });

        svg.append('rect')
            .attr('width', width)
            .attr('height', height)
            .style('fill', 'none')
            .style('pointer-events', 'all')
            .call(zoom)
            .on('wheel', event => event.preventDefault());

    }, [graphData, dimensions]);
    if (!graphData.filteredCurrentYear?.length && !graphData.filteredReference?.length) {
        return null;
    }

    return (
        <div className="w-full h-full flex flex-col items-center">
            <div className="text-sm mb-2 flex justify-center items-center" style={{ fontWeight: "bold", textAlign: "center" }}>
                {graphData.filteredReference?.length > 0 && (
                    <>
                        <svg width="20" height="20" className="mr-2">
                            <line x1="0" y1="10" x2="20" y2="10" stroke="#2196f3" strokeWidth="2" />
                        </svg>
                        <span style={{ margin: '5px' }}>{t("Reference field")}</span>
                    </>
                )}
                {graphData.filteredCurrentYear?.length > 0 && (
                    <>
                        <svg width="20" height="20" className="mr-2">
                            <line x1="0" y1="10" x2="20" y2="10" stroke="#ff4081" strokeWidth="2" />
                        </svg>
                        <span>{taskName}</span>
                    </>
                )}
            </div>

            <div className="w-full h-[800px]">
                <div className="chart-container w-full h-full">
                    <svg ref={svgRef} className="w-full h-full" />
                </div>
            </div>
        </div>
    );
};

export default TaskReferenceComparisonGraph;
