import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  KeepMeSignedIn,
  TimeOutLogOut,
  load_user,
  login,
  setUpRefreshInterval,
} from "../actions/auth";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
// import Card from "react-bootstrap/Card";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";

import Row from "react-bootstrap/Row";
import logo from "../assets/images/logo_login.png";
import crop from "../assets/images/crop.png";
import "../assets/css/login.css";
import { useDispatch } from "react-redux";
import Loading from "../utils/loading";
import { loadingBegin, loadingEnd } from "../actions/map";
import {
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { showSnackbar } from "../actions/snackbar";
import { useTranslation } from "react-i18next";
import LoadingButton from "@mui/lab/LoadingButton";
import LoginIcon from "@mui/icons-material/Login";
import { KEEP_ME_SIGN_IN_CHECKED } from "../utils/types";

const Login = () => {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    rememberMe: false,
  });
  const btnSX = {
    backgroundColor: "#6f9d2f",
    paddingTop: "2px",
    paddingBottom: "2px",
    paddingRight: "60px",
    paddingLeft: "60px",
    borderRadius: "5px",
    "&:hover": {
      backgroundColor: "#618a27",
    },
    "& span": {
      textTransform: "capitalize",
      fontSize: "16px",
    },
    "& span.MuiLoadingButton-loadingIndicator": {
      left: "30px",
    },
  };
  //   let { reset } = useParams();
  const queryParameters = new URLSearchParams(window.location.search);
  const reset = queryParameters.get("reset");
  const activate = queryParameters.get("activate");
  const { t } = useTranslation();

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const [loadingbtn, setLoadingbtn] = useState(false);

  const { email, password, rememberMe } = formData;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(loadingBegin(1));
  }, []);
  const loading = useSelector((state) => state.mapReducer.LoadingState);
  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const handleRememberMe = (event) => {
    setFormData({ ...formData, rememberMe: event.target.checked });
  };
  const onSubmit = (e) => {
    setLoadingbtn(true);
    e.preventDefault();
    dispatch(login(email, password, rememberMe))
      .then((res) => {
        res.resnpence = undefined;
        if (res.status !== 200) {
          if(res.response.status === 401){
            setLoadingbtn(false);
            dispatch(
                showSnackbar(
                    "error",
                    undefined,
                    t("please verify your credentials !"),
                    "top",
                    "right"
                )
            );
          }
          else {
            setLoadingbtn(false);
            dispatch(
                showSnackbar(
                    "error",
                    undefined,
                    t("You are not authorized to log in"),
                    "top",
                    "right"
                )
            );
          }
        } else {
          if (rememberMe) {

            dispatch(load_user()).then((res) => {
              setLoadingbtn(false);
              navigate("/dashboard");
            });
            dispatch(setUpRefreshInterval());
          } else {
            dispatch(load_user()).then((res) => {
              setLoadingbtn(false);
              navigate("/dashboard");
              dispatch(TimeOutLogOut());
            });
          }
        }
      })
      .catch((error) => {
        dispatch(
          showSnackbar(
            "error",
            undefined,
            t("something went wrong ! please try again later"),
            "top",
            "right"
          )
        );
        setLoadingbtn(false);
      });
  };

  useEffect(() => {
    setTimeout(() => dispatch(loadingEnd(0)), 1000);
  }, []);
  useEffect(() => {
    if (reset && reset === "true") {
      dispatch(
        showSnackbar(
          "success",
          undefined,
          t(
            "Your password has been changed successfully. You can now log in with your new password."
          ),
          "top",
          "right"
        )
      );
    }
  }, [reset]);
  useEffect(() => {
    if (activate && activate === "true") {
      dispatch(
        showSnackbar(
          "success",
          undefined,
          t(
            "Your account has been confirmed successfully. You can now log in."
          ),
          "top",
          "right"
        )
      );
    }
  }, [activate]);

  useEffect(() => {
    document.body.classList.add("background-body");
    return () => {
      document.body.classList.remove("background-body");
    };
  }, []);

  return (
    <Container className="sign-in-container auth-container">
      <Loading />
      <Row>
        <div className="justify-content-center col-12 d-flex">
          <div className="col-md-4 text-center mb-3">
            <img src={logo} alt="logo" style={{ width: "100%" }} />
          </div>
        </div>
        <div className="justify-content-center col-12 d-flex">
          <Card className="col-sm-12 col-md-10 card-login">
            <div className="col-12 d-flex flex-wrap">
              <div className="col-12 col-md-6 left-block-login">
                <div className="container">
                  <Row className="justify-content-center">
                    <div className="col-sm-12 col-md-8 text-center slogon">
                      {t("The solution for modern agricultural care")}
                    </div>
                  </Row>
                  <Row className="justify-content-center">
                    <div className="col-sm-12 col-md-6 text-center mb-4 mt-4">
                      <img src={crop} style={{ width: "100%" }} alt="crop" />
                    </div>
                  </Row>
                  <Row className="justify-content-center">
                    <div className="col-md-4 text-center">
                      <Link to={"https://www.robocare.tn/"} target="_blank">
                        <Button className="btn-learn-more">
                          {t("Learn More")}
                        </Button>
                      </Link>
                    </div>
                  </Row>
                </div>
              </div>
              <div className="col-12 col-md-6 d-flex align-items-center right-block-login">
                <div className="container">
                  <Form
                    onSubmit={(e) => onSubmit(e)}
                    className="text-center mb-3"
                  >
                    <h1>{t("Sign In")}</h1>
                    <p>{t("Sign into your account")}</p>
                    <Row className="mb-3">
                      <div className="col-md-12">
                        <FormControl
                          fullWidth
                          variant="outlined"
                          className="sign-up-input"
                        >
                          <TextField
                            id="outlined-size-small"
                            type="email"
                            label="E-mail"
                            name="email"
                            value={email}
                            onChange={(e) => onChange(e)}
                            required
                            size="small"
                          />
                        </FormControl>
                      </div>
                    </Row>
                    <Row className="mb-1">
                      <div className="col-md-12">
                        <FormControl
                          fullWidth
                          variant="outlined"
                          className="sign-up-input"
                        >
                          <InputLabel htmlFor="outlined-adornment-password">
                            {t("Password")} *
                          </InputLabel>
                          <OutlinedInput
                            size="small"
                            id="outlined-adornment-password"
                            type={showPassword ? "text" : "password"}
                            endAdornment={
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowPassword}
                                  onMouseDown={handleMouseDownPassword}
                                  edge="end"
                                >
                                  {showPassword ? (
                                    <VisibilityOff />
                                  ) : (
                                    <Visibility />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            }
                            label="Password *"
                            name="password"
                            value={password}
                            onChange={(e) => onChange(e)}
                            minLength="6"
                            required
                          />
                        </FormControl>
                      </div>
                    </Row>

                    <div className="col-md-12 mb-5">
                      <Row>
                        <div className="col-md-6 text-left signin-checkbox">
                          <input
                            id="signinCheckbox"
                            type="checkbox"
                            style={{ marginRight: "5px" }}
                            onChange={handleRememberMe}
                            value={rememberMe}
                          />
                          <label
                            className="label-signinCheckbox"
                            htmlFor="signinCheckbox"
                          >
                            {t("Keep me signed in")}
                          </label>
                        </div>
                        <div className="col-md-6 text-right">
                          <Link
                            to="/reset-password"
                            className="reset-pass-link"
                          >
                            {t("Reset Password ?")}
                          </Link>
                        </div>
                      </Row>
                    </div>
                    <div className="col-md-6 offset-md-3">
                      <LoadingButton
                        sx={btnSX}
                        size="small"
                        type="submit"
                        loading={loadingbtn}
                        loadingPosition="start"
                        variant="contained"
                        startIcon={<LoginIcon />}
                      >
                        <span>{t("Login")}</span>
                      </LoadingButton>
                    </div>
                  </Form>
                  <div className="col-md-12 text-center">
                    <p className="mt-3 signup-p">
                      {/* {t("Don't have an account ?")} <Link to="#" className="signup-link">{t("Sign up")}</Link> */}
                      {/*{t("Don't have an account ?")}{" "}*/}
                      <Link to="/signup" className="signup-link">
                      {/*<Link to="/pricing" className="signup-link">
                        {t("Sign up")}*/}
                        {t("Start free trial")}
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </Card>
        </div>
      </Row>
    </Container>
  );
};

export default Login;
